import axios from 'axios';
import { defineComponent } from 'vue';
import Loader from '@/core/components/ui/Loader.vue';
import StringHelper from '@/core/helpers/string.helper';
import { VUE_APP_PROPERTY_DATA_API, VUE_APP_PROPERTY_DATA_URL, } from '../../../config';
const POSITIVE = 'positive';
const NEGATIVE = 'negative';
const NEUTRAL = 'neutral';
export default defineComponent({
    components: {
        Loader
    },
    data() {
        return {
            searchKey: '',
            planningFilterData: {
                decisionRating: '',
                categories: [],
                age: 120,
                result: 10
            },
            planningData: null,
            loadingPlanningResult: false,
            hasNoPlanningData: false,
            greenBeltData: null,
            loadingGreenBeltResult: false,
            nationalParkData: null,
            loadingNationalParkResult: false,
            conservationAreaData: null,
            loadingConservationAreaResult: false,
            aonbData: null,
            loadingAonbResult: false,
            floodRiskData: null,
            loadingFloodRiskResult: false,
            listedBuildingsData: null,
            loadingListedBuildingsResult: false,
            areaTypeData: null,
            loadingAreaTypeResult: false,
            decisionRatingOptions: [{
                    value: POSITIVE,
                    label: StringHelper.capitalizeFirstLetter(POSITIVE)
                },
                {
                    value: NEGATIVE,
                    label: StringHelper.capitalizeFirstLetter(NEGATIVE)
                },
                {
                    value: NEUTRAL,
                    label: StringHelper.capitalizeFirstLetter(NEUTRAL)
                }],
            planningCategories: [
                {
                    value: 'EXTENSION',
                    label: 'EXTENSION'
                },
                {
                    value: 'HOUSING UNITS',
                    label: 'HOUSING UNITS'
                },
                {
                    value: 'NEW BUILD',
                    label: 'NEW BUILD'
                },
                {
                    value: 'LOFT CONVERSION',
                    label: 'LOFT CONVERSION'
                },
                {
                    value: 'AGRICULTURAL BUILDING',
                    label: 'AGRICULTURAL BUILDING'
                },
                {
                    value: 'GARAGE CONVERSION',
                    label: 'GARAGE CONVERSION'
                },
                {
                    value: 'BAR/CAFE/RESTAURANT',
                    label: 'BAR/CAFE/RESTAURANT'
                },
                {
                    value: 'CONSERVATORY',
                    label: 'CONSERVATORY'
                },
                {
                    value: 'RETAIL UNITS',
                    label: 'RETAIL UNITS'
                },
                {
                    value: 'OFFICE',
                    label: 'OFFICE'
                },
                {
                    value: 'SPORT AND LEISURE FACILITY',
                    label: 'SPORT AND LEISURE FACILITY'
                },
                {
                    value: 'INDUSTRIAL UNIT',
                    label: 'INDUSTRIAL UNIT'
                },
                {
                    value: 'MIXED USE DEVELOPMENT',
                    label: 'MIXED USE DEVELOPMENT'
                },
                {
                    value: 'STORAGE FACILITY',
                    label: 'STORAGE FACILITY'
                },
                {
                    value: 'HEALTHCARE CENTRE',
                    label: 'HEALTHCARE CENTRE'
                },
                {
                    value: 'CARE HOME',
                    label: 'CARE HOME'
                },
                {
                    value: 'HOTEL',
                    label: 'HOTEL'
                },
                {
                    value: 'PRIMARY SCHOOL',
                    label: 'PRIMARY SCHOOL'
                },
                {
                    value: 'BASEMENT CONVERSION',
                    label: 'BASEMENT CONVERSION'
                },
                {
                    value: 'COMMUNITY CENTRE',
                    label: 'COMMUNITY CENTRE'
                },
                {
                    value: 'CHILD CARE FACILITY',
                    label: 'CHILD CARE FACILITY'
                },
                {
                    value: 'FURTHER EDUCATION ESTAB.',
                    label: 'FURTHER EDUCATION ESTAB.'
                },
                {
                    value: 'PLACE OF WORSHIP',
                    label: 'PLACE OF WORSHIP'
                },
                {
                    value: 'VEHICLE AND PARTS RETAIL',
                    label: 'VEHICLE AND PARTS RETAIL'
                },
                {
                    value: 'SECONDARY SCHOOL',
                    label: 'SECONDARY SCHOOL'
                },
                {
                    value: 'SCHOOL (OTHER)',
                    label: 'SCHOOL (OTHER)'
                },
                {
                    value: 'PETROL RETAIL',
                    label: 'PETROL RETAIL'
                },
                {
                    value: 'CLUBHOUSE AND PAVILION',
                    label: 'CLUBHOUSE AND PAVILION'
                },
                {
                    value: 'HOSPITAL',
                    label: 'HOSPITAL'
                },
                {
                    value: 'LIBRARY/MUSEUM/GALLERY',
                    label: 'LIBRARY/MUSEUM/GALLERY'
                },
                {
                    value: 'COMMERCIAL DEVELOPMENT',
                    label: 'COMMERCIAL DEVELOPMENT'
                },
                {
                    value: 'SUPERMARKET',
                    label: 'SUPERMARKET'
                },
            ],
        };
    },
    methods: {
        async onSearchPlanningData() {
            this.loadingPlanningResult = true;
            this.planningData = [];
            if (this.searchKey.length > 3) {
                const postcode = this.searchKey;
                this.getPlanningData(postcode, this.planningFilterData);
            }
            else {
                this.$notify.error({
                    title: 'Invalid postcode',
                    message: 'Please enter a valid postcode.'
                });
            }
        },
        async getPlanningData(postcode, planningFilterData) {
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/planning?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${postcode}&decision_rating=${planningFilterData.decisionRating}&category=${planningFilterData.categories}&max_age_update=${planningFilterData.age}&results=${planningFilterData.result}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.planningData = response.data;
                this.hasNoPlanningData = false;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no data planning data available.'
                });
                this.hasNoPlanningData = true;
            })
                .finally(() => {
                this.loadingPlanningResult = false;
            });
        },
        async getGreenBelt() {
            this.loadingGreenBeltResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/green-belt?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.greenBeltData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no green belt data available.'
                });
            })
                .finally(() => {
                this.loadingGreenBeltResult = false;
            });
        },
        async getNationalPark() {
            this.loadingNationalParkResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/national-park?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.nationalParkData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no national park data available.'
                });
            })
                .finally(() => {
                this.loadingNationalParkResult = false;
            });
        },
        async getConservationArea() {
            this.loadingConservationAreaResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/conservation-area?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.conservationAreaData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no conservation area data available.'
                });
            })
                .finally(() => {
                this.loadingConservationAreaResult = false;
            });
        },
        async getAonb() {
            this.loadingAonbResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/aonb?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.aonbData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no AONB data available.'
                });
            })
                .finally(() => {
                this.loadingAonbResult = false;
            });
        },
        async getFloodRisk() {
            this.loadingFloodRiskResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/flood-risk?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.floodRiskData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no Flood risk data available.'
                });
            })
                .finally(() => {
                this.loadingFloodRiskResult = false;
            });
        },
        async getListedBuildings() {
            this.loadingListedBuildingsResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/listed-buildings?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.listedBuildingsData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no Listed buildings data available.'
                });
            })
                .finally(() => {
                this.loadingListedBuildingsResult = false;
            });
        },
        async getAreaType() {
            this.loadingAreaTypeResult = true;
            const requestUrl = `${VUE_APP_PROPERTY_DATA_URL}/area-type?key=${VUE_APP_PROPERTY_DATA_API}&postcode=${this.searchKey}`;
            await axios.get(`${requestUrl}`, {
                headers: {
                    Accept: 'application/json',
                }
            })
                .then((response) => {
                this.areaTypeData = response.data;
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'Postcode is invalid or no Area type data available.'
                });
            })
                .finally(() => {
                this.loadingAreaTypeResult = false;
            });
        },
    },
});
